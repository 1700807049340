import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout/Layout'
import Description from "../components/terms-of-use-page/Description";

const TermsOfUse = (props) => {
    return (
        <Layout path={props.location.pathname}>
            {/*<Seo />*/}
            <Description />
        </Layout>
    )
}

export default TermsOfUse

